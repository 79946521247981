import classNames from 'classnames/bind';
import React from 'react';

import styles from './AnimatedPath.module.scss';

interface IProps {
  path: JSX.Element;
  delayMs?: number;
  durationMs?: number;
  className?: string;
  align?: 'none' | 'center';
}

const cx = classNames.bind(styles);

export const AnimatedPath: React.FC<IProps> = ({
  path,
  delayMs = 500,
  durationMs = 1000,
  align = 'none',
  className,
}) => {
  const squareRef = React.useRef<HTMLDivElement>(null);
  const pathWrapperRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    const pathWrapper = pathWrapperRef?.current;

    if (pathWrapper) {
      const svg = pathWrapper.querySelector('svg');
      const path = svg?.querySelector('path');
      if (svg && path) {
        const pathPosition = path.getBoundingClientRect();
        const pathTotalLength = path.getTotalLength();
        svg.style.strokeDasharray = pathTotalLength.toFixed(0);
        svg.style.strokeDashoffset = pathTotalLength.toFixed(0);
        svg.style.opacity = '1';

        const positionElements = () => {
          const pointPercentage = pathPosition.height;
          const pointOnPath = pointPercentage * pathTotalLength;
          const pathPoint = path.getPointAtLength(pointOnPath);

          if (squareRef.current) {
            squareRef.current.style.transform = `translate(${pathPoint.x}px, ${pathPoint.y}px)`;
          }
        };

        positionElements();
      }
    }
  }, []);

  return (
    <div className={cx('wrapper', align, className)}>
      <div className={styles.inner}>
        <div ref={pathWrapperRef}>
          {React.cloneElement(path, {
            className: styles.path,
            style: { animationDelay: `${delayMs}ms`, animationDuration: `${durationMs}ms` },
          })}
        </div>
        {React.cloneElement(path, {
          className: styles.overlay,
        })}
        <div ref={squareRef} className={styles.squareWrapper}>
          <div className={styles.square} style={{ animationDelay: `${delayMs + durationMs}ms` }} />
        </div>
      </div>
    </div>
  );
};
