import React from 'react';

import { LinesBackground } from '../LinesBackground/LinesBackground';
import { AboutUsStats } from './AboutUsStats/AboutUsStats';
import { Container } from '@components/Layout/Container/Container';
import { Section } from '@components/Section/Section';
import { Text } from '@components/Text/Text';

import styles from './AboutUsHeader.module.scss';

export const AboutUsHeader: React.FC = () => (
  <div className={styles.header}>
    <Section padding={{ top: 170, bottom: 150 }} tag="header">
      <Container>
        <LinesBackground />
        <div>
          <Text
            align="center"
            className="mb-6"
            color="gray900"
            display="block"
            maxWidth={550}
            size={56}
            tag="h1"
            weight="bold"
            isCentered
          >
            Bringing client focus to the CDN market
          </Text>
          <Text
            align="center"
            className="mb-8"
            display="block"
            maxWidth={770}
            size={18}
            tag="p"
            isCentered
          >
            At CDN77, we move mountains for our clients. We have been providing critical
            infrastructure for some of the world’s most trafficked websites and apps for over 12
            years. By continuously advancing our platform and pushing it to the edge, we’re
            dedicated to ensuring our clients excel in every facet of their business.
          </Text>
        </div>
        <AboutUsStats />
      </Container>
    </Section>
  </div>
);
